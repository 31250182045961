import React from 'react'

const vorstand = [
    {
        name: 'Bodo Wagner',
        position: '1. Vorsitzender',
        gender: 'm',
        email: 'bodowagner@ttcrehlingen.de',
    },
    {
        name: 'Jürgen Klasen',
        position: '2. Vorsitzender',
        gender: 'm',
        email: 'juergenklasen@ttcrehlingen.de',
    },
    {
        name: 'Stefan Klasen',
        position: 'Schriftführer',
        gender: 'm',
        email: 'stefanklasen@ttcrehlingen.de',
    },
    {
        name: 'Bernd Görgen',
        position: 'Kassenwart',
        gender: 'm',
        email: 'berndgoergen@ttcrehlingen.de',
    },
    {
        name: 'Joe Meiers',
        position: 'Spielausschussvorsitzender',
        gender: 'm',
        email: 'joemeiers@ttcrehlingen.de',
    },
    {
        name: 'Benjamin Brenner',
        position: 'Jugendwart',
        gender: 'm',
        email: '',
    },
    {
        name: 'Dietmar Sieren',
        position: 'Gerätewart',
        gender: 'm',
        email: '',
    },
    {
        name: 'Thomas Kreuzahler',
        position: 'Pressewart',
        gender: 'm',
        email: 'thomaskreuzahler@ttcrehlingen.de',
    },
    {
        name: 'Birgit Weides',
        position: 'Beisitzer',
        gender: 'w',
        email: 'birgitweides@ttcrehlingen.de',
    },
    {
        name: 'Sandra Ehl',
        position: 'Beisitzer',
        gender: 'w',
        email: '',
    },
    {
        name: 'Tim Pauluhn',
        position: 'Beisitzer',
        gender: 'm',
        email: 'timpauluhn@ttcrehlingen.de',
    },
    {
        name: 'Alexander Schramm',
        position: 'Beisitzer',
        gender: 'm',
        email: '',
    },
    {
        name: 'Michael Kirsch',
        position: 'Beisitzer',
        gender: 'm',
        email: '',
    },
    {
        name: 'Sabrina Amann',
        position: 'Beisitzer',
        gender: 'w',
        email: '',
    },
];



export default function Vorstand() {
  return (
    <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto  lg:px-0">
                <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8 absolute top-80 sm:top-72 md:top-72 lg:top-72 xl:top-72 left-0 right-0">
                    <h2 className="mb-4 text-2xl sm:text-3xl lg:text-4xl tracking-tight font-extrabold text-white dark:text-white">Vorstand</h2>
                    <p className="font-light text-slate-100 text-sm sm:text-lg lg:text-xl text-white dark:text-gray-400">Unsere Vereinsführung: Erfahren, engagiert und mit Herz dabei</p>
                </div>
                <section className="bg-white dark:bg-gray-900">
                    <div className="mx-auto max-w-screen-2xl  lg:px-6 ">
                        <div className="mx-auto text-center mb-8 lg:mb-16">
                            <img className="w-full rounded-3xl mx-auto" src={require('../assets/vorstand.jpg')} alt="Vorstand" />
                        </div> 
                        <div className="grid gap-10 mb-6 lg:mb-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                            {vorstand.map((member, index) => (
                                <div key={index} className="items-center p-4 bg-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                    <a href="/">
                                        <img className="rounded-full mx-auto w-1/2  md:w-5/6" src={member.gender === 'm' ? require('../assets/avatar_m.png') : require('../assets/avatar_w.png')} alt="Avatar"/>
                                    </a>
                                    <div className="mt-4 flex-col">
                                        <h3 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                                            <a href="/">{member.name}</a>
                                        </h3>
                                        <div>
                                            <span className="text-lg text-gray-700 dark:text-gray-400 font-medium">{member.position}</span>
                                        </div>
                                        <div>
                                            <a href={`mailto:${member.email}`} className="mt-3 font-normal text-sm md:text-xs text-gray-500 dark:text-gray-400">{member.email.length > 0 && member.email}</a>
                                        </div>
                                    </div>
                                </div>   
                            ))}
                        </div>
                    </div>
                </section>
{/*                 <div className='w-full sm:w-4/5 lg:w-3/4 mx-auto px-4 pt-10 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 lg:gab-8 justify-center text-sm lg:text-lg'>
                    <p className="block py-3 px-5 text-gray-70">Coming soon</p>
                </div>  */}
            </div>
        </section>
  )
}
